import React, { useState } from "react";
import { Form, Input, Checkbox, Upload, Button, message, Col } from "antd";
import { UploadOutlined } from "@ant-design/icons";
import LogoComponent from "../LogoComponent";
import { useNavigate } from "react-router-dom";
import AxiosInstance from "../../Config/axios";

interface SubContent {
  no: number;
  subHeading: string;
  content: string;
}

interface Blog {
  main: string;
  subMain: string;
  mainImg: string;
  imgPreview: string;
  footerFlag: boolean;
  footerLink: string;
  footerText: string;
  subContent: SubContent[];
}

const AddBlog: React.FC = () => {
  const [form] = Form.useForm();
  const [sameImage, setSameImage] = useState(false);
  //eslint-disable-next-line
  const [mainImg, setMainImg] = useState<string>("");
  const accessToken = localStorage.getItem("accessToken");

  const handleImageUpload = async (file: any) => {
    try {
      const formData = new FormData();
      formData.append("image", file.file);
      const response = await AxiosInstance.post(
        "/api/blog/upload-image",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
            token: `Bearer ${accessToken}`,
          },
        }
      );
      setMainImg(response.data.url);
    } catch (error) {
      message.error("Failed to upload image");
    } finally {
    }
  };

  const handleSubmit = async (values: Blog) => {
    if (sameImage) {
      values.imgPreview = mainImg;
    }

    const response = await AxiosInstance.post(
      "/api/blog",
      { ...values, mainImg },
      {
        headers: {
          token: `Bearer ${accessToken}`,
        },
      }
    );

    if (response.status === 200) {
      message.success("Blog submitted successfully!");
      navigate("/");
    } else {
      message.error("Failed to submit blog");
    }
  };
  const navigate = useNavigate();

  return (
    <>
      <div className=" border-bottom py-3">
        <div className=" d-flex justify-content-between container">
          <h1
            className="m-0 text-primary"
            style={{ cursor: "pointer" }}
            onClick={() => navigate("/")}
          >
            Paytrend
          </h1>
          <LogoComponent />
        </div>
      </div>
      <Col className="mt-5">
        <Form
          form={form}
          layout="vertical"
          onFinish={handleSubmit}
          initialValues={{ footerFlag: false, subContent: [] }}
          style={{ maxWidth: "800px", margin: "0 auto" }}
        >
          <Form.Item
            label="Main Title"
            name="main"
            rules={[{ required: true, message: "Main title is required" }]}
          >
            <Input placeholder="Enter the main title" />
          </Form.Item>
          <Form.Item label="Summary" name="subMain">
            <Input placeholder="Enter the subtitle" />
          </Form.Item>
          <Form.Item
            label="Main Image"
            name="mainImg"
            rules={[{ required: true, message: "Main image is required" }]}
          >
            <Upload
              name="file"
              listType="picture"
              customRequest={(file) => handleImageUpload(file)}
            >
              <Button icon={<UploadOutlined />}>Upload Main Image</Button>
            </Upload>
          </Form.Item>
          <Form.Item name="useSameImage" valuePropName="checked">
            <Checkbox onChange={(e) => setSameImage(e.target.checked)}>
              Use the same image for preview
            </Checkbox>
          </Form.Item>
          {!sameImage && (
            <Form.Item label="Preview Image" name="imgPreview">
              <Upload
                name="file"
                listType="picture"
                customRequest={({ file }) => handleImageUpload(file)}
              >
                <Button icon={<UploadOutlined />}>Upload Preview Image</Button>
              </Upload>
            </Form.Item>
          )}
          <Form.Item
            label="Footer Flag"
            name="footerFlag"
            valuePropName="checked"
          >
            <Checkbox>Show Footer</Checkbox>
          </Form.Item>
          <Form.Item label="Footer Link" name="footerLink">
            <Input placeholder="Enter the footer link" />
          </Form.Item>
          <Form.Item label="Footer Text" name="footerText">
            <Input placeholder="Enter the footer text" />
          </Form.Item>
          {/* SubContent would be added dynamically; simplified for now */}
          <Form.Item>
            <Button type="primary" htmlType="submit">
              Submit Blog
            </Button>
          </Form.Item>
        </Form>
      </Col>
    </>
  );
};

export default AddBlog;
