import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  Dispatch,
  SetStateAction,
  useEffect,
} from "react";

interface AppContextProps {
  setIsMobile: Dispatch<SetStateAction<boolean>>;
  isMobile: boolean;
  selectedJobRole: string;
  setSelectedJobRole: Dispatch<SetStateAction<string>>;
  averageValue: any;
  setAverageValue: Dispatch<SetStateAction<any>>;
  userData: any;
  setUserData: Dispatch<SetStateAction<any>>;
  maxSalaryThresh: any[];
  setMaxSalaryThresh: Dispatch<SetStateAction<any>>;
}

const MyContext = createContext<AppContextProps | undefined>(undefined);

interface AppContextProviderProps {
  children: ReactNode;
}

export const AppContextProvider: React.FC<AppContextProviderProps> = ({
  children,
}: AppContextProviderProps) => {
  const [isMobile, setIsMobile] = useState<boolean>(false);
  const [selectedJobRole, setSelectedJobRole] = useState("");
  const [averageValue, setAverageValue] = useState("");
  const [userData, setUserData] = useState(null);
  const [isVerifyingOtp, setIsVerifyingOtp] = useState(false);
  const [maxSalaryThresh, setMaxSalaryThresh] = useState([]);

  const value: AppContextProps = {
    isMobile,
    setIsMobile,
    selectedJobRole,
    setSelectedJobRole,
    averageValue,
    setAverageValue,
    userData,
    setUserData,
    setMaxSalaryThresh,
    maxSalaryThresh,
  };

  useEffect(() => {
    // Check if the screen width is less than a certain value (e.g., 768px) to determine if it's a mobile device
    const handleResize = () => {
      setIsMobile(window.innerWidth < 913);
    };

    // Add an event listener to handle window resizing
    window.addEventListener("resize", handleResize);

    // Initial check
    handleResize();

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return <MyContext.Provider value={value}>{children}</MyContext.Provider>;
};

export const useApplicationContext = (): AppContextProps => {
  const context = useContext(MyContext);
  if (!context) {
    throw new Error(
      "useApplicationContext must be used within an AppContextProvider"
    );
  }
  return context;
};
